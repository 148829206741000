export const isBluetoothEnabled = () => {
  return navigator.bluetooth ? true : false;
};

/**
 * BT Module loading entry
 */
export const bt_hello = () => {
  console.log("SMART_CONTROL_APP v1.0.0 Bluetooth Module");
};

export const UUID = {
  BLE_SERVICE_URT_UUID16: 0x1234,
  BLE_SERVICE_URT_UUID128: "5320e7d3-4e63-472b-b5da-ab54f8a10aeb",
  BLE_CHARACTERISTIC_UUID_NEC_ADR_CMD: "07fee8c0-b6f9-4ed0-8e42-312f0bbce1a6",
  BLE_CHARACTERISTIC_UUID_EVENT_STATISTIC: 0x2af4,
  BLE_CHARACTERISTIC_UUID_MODEL_NUMBER: 0x2a24,
};
