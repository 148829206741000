import "./App.css";
import { FaTabletScreenButton } from "react-icons/fa6";
import { bt_hello, isBluetoothEnabled, UUID } from "./bluetooth";
import { useState } from "react";

let addr_cmd_characteristic;
let utf8decoder = new TextDecoder();

function App() {
  bt_hello();
  const [device_status, set_device_status] = useState("Not Connected");
  const [connect_status, set_connect_status] = useState(false);
  const [nec_address, set_nec_address] = useState("0000");
  const [nec_command, set_nec_command] = useState("0000");

  const notify_update = (event) => {
    let value = event.target.value;
    let decoded = utf8decoder.decode(value);
    const [addr, cmd] = decoded.split("-");
    set_nec_address(addr);
    set_nec_command(cmd);
  };

  const deviceDisconnect = () => {
    set_device_status("Disconnecting, please wait...");
    addr_cmd_characteristic.stopNotifications().then((_) => {
      addr_cmd_characteristic.removeEventListener(
        "characteristicvaluechanged",
        () => {}
      );
      addr_cmd_characteristic.service.device.gatt.disconnect();
      set_connect_status(false);
      set_device_status("Not Connected");
    });
  };

  const deviceSelector = () => {
    if (!isBluetoothEnabled()) {
      console.error("Web Bluetooth API is not supported on this device");
      set_device_status("Web Bluetooth not supported");
      return;
    }
    navigator.bluetooth
      .requestDevice({
        filters: [
          {
            name: "JAN'S URT v1",
            services: [UUID.BLE_SERVICE_URT_UUID16],
          },
        ],
      })
      .then((device) => {
        console.log(device);
        return device.gatt.connect();
      })
      .then((gatt_server) => {
        set_device_status("Connected to JAN'S URT v1");
        set_connect_status(true);
        return gatt_server.getPrimaryService(UUID.BLE_SERVICE_URT_UUID16);
      })
      .then((service) => {
        return service.getCharacteristic(
          UUID.BLE_CHARACTERISTIC_UUID_NEC_ADR_CMD
        );
      })
      .then((characteristic) => {
        addr_cmd_characteristic = characteristic;
        return addr_cmd_characteristic.startNotifications().then((_) => {
          addr_cmd_characteristic.addEventListener(
            "characteristicvaluechanged",
            notify_update
          );
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const SelectorButton = ({ status }) => {
    if (status) {
      return (
        <button className="outline p-2 mt-2 rounded" onClick={deviceDisconnect}>
          <FaTabletScreenButton className="inline-block"></FaTabletScreenButton>{" "}
          Disconnect
        </button>
      );
    }
    return (
      <button className="outline p-2 mt-2 rounded" onClick={deviceSelector}>
        <FaTabletScreenButton className="inline-block"></FaTabletScreenButton>{" "}
        Select Device
      </button>
    );
  };

  return (
    <div className="app flex flex-col items-center justify-center">
      <div className="app-device_connect_status">
        <p className="text-3xl font-bold">Control Center</p>
        <SelectorButton status={connect_status} />
        <p className="text-xs mt-2">Status: {device_status}</p>
      </div>
      <div className="app-main flex flex-row flex-wrap mt-4">
        <div className="app-main-monitor border border-dotted m-2 border-gray-300">
          <div className="border-b border-dotted font-semibold">Monitor</div>
          <div className="flex flex-row justify-center text-sm font-mono">
            <p>Address:&nbsp;</p>
            <p>{nec_address}</p>
          </div>
          <div className="flex flex-row justify-center text-sm font-mono">
            <p>Command:&nbsp;</p>
            <p>{nec_command}</p>
          </div>
        </div>
        <div className="app-main-control border border-dotted m-2 border-gray-300">
          <div className="border-b border-dotted font-semibold">Control</div>
        </div>
      </div>
      <div className="app-footer mt-3">
        <p className="text-sm">
          Made by&nbsp;
          <a
            className="underline"
            href="https://jmahanta.in"
            target="_blank"
            rel="noreferrer"
          >
            Jan
          </a>
        </p>
        <p className="text-sm font-mono italic">testbuild: 0.0.5</p>
      </div>
    </div>
  );
}

export default App;
